<template>
  <v-app>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
          @submit.prevent="handleSubmit(addWord)"
          method="post"
          id="check-input-form"
      >
        <ValidationProvider rules="required|minmax:0,10" v-slot="{ errors }">
          <div class="font-weight-bold">Nhập các từ cần điền vào ô bên dưới</div>
          <v-row>
            <v-col class="text-center">
              <v-text-field
                  v-model="newWord"
                  label="Từ mới"
              ></v-text-field>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </v-col>
            <v-col>
              <v-btn color="black" class="white--text" type="submit"
                     depressed
                     form="check-input-form"
              >Thêm từ
              </v-btn>
            </v-col>
          </v-row>

        </ValidationProvider>
      </v-form>
    </ValidationObserver>
    <v-row>
      <v-col cols="12">
        <v-dialog
            v-model="dialog2"
            width="500"
        >
          <template v-slot:activator="{ on, attrs }">
          </template>

          <v-card>
            <v-card-title class="headline lighten-2">
              Bị lặp từ
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialog2 = false"
                >
                  I accept
                </v-btn>
              </v-card-actions>
            </v-card-title>

          </v-card>
        </v-dialog>
        <v-data-table
            :headers="headers"
            :items="groupWords"
            :items-per-page="10"
            class="elevation-1 text-center"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
            >mdi-pencil
            </v-icon
            >
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.group_type="{ item }">
            <v-checkbox v-model="item.group_type"></v-checkbox>
          </template>
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }"></template>
              <ValidationObserver v-slot="{ handleSubmit }">
                <v-form
                    @submit.prevent="handleSubmit( close )"
                    method="post"
                    id="check-edit-form"
                >
                  <ValidationProvider rules="required|minmax:0,10" v-slot="{ errors }">
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6" md="4">
                              <!-- validation in here  -->
                              <v-text-field
                                  v-model="editedItem.content"
                                  label="Từ"
                              ></v-text-field>
                              <span class="validate-error text-danger text-danger">
                                {{ errors[0] }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" type="submit"
                               depressed
                               form="check-edit-form">
                          Ok
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>
            </v-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert v-if="message.success != ''" text type="success">{{
            message.success
          }}
        </v-alert>
        <v-alert v-if="message.error != ''" text type="error">{{
            message.success
          }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <v-btn :disabled="disable_save" class="primary" @click="submitWord">Lưu</v-btn>
      </v-col>
    </v-row>
  </div>
  </v-app>
</template>
<script>
import {
  GET_EXERCISE_DETAIL,
  SAVE_EXERCISE_CONTENT,
  UPDATE_EXERCISE_CONTENT,
  GET_EXERCISE_CONTENT,
} from "@/store/exercises.module";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      disable_save:true,
      newIndex: -1,
      editedItem: {
        id: 0,
        content: "",
        group_type: true,
      },
      message: {
        success: "",
        error: "",
      },
      dialog2: false,
      editedIndex: -1,
      dialog: false,
      newWord: "",
      headers: [
        {text: "Từ", value: "content"},
        {text: "Chọn từ không cùng nhóm", value: "group_type"},
        {text: "Thao tác", value: "actions", sortable: false},
      ],
      exercise: {},
      group : 1,
      groupWords : [],
      isUpdate: false,
    };
  },
  async created() {
    await this.$store.dispatch(GET_EXERCISE_DETAIL, {
      exercise_slug: this.$route.query.exercise_slug,
    });
    await this.$store.dispatch(GET_EXERCISE_CONTENT, {
      exercise_slug: this.$route.query.exercise_slug,
    });
    if (this.$route.query.group) {
      this.isUpdate = true;
      this.groupWords = [...this.words[this.$route.query.group]]
    }
  },
  computed: {
    ...mapGetters({
      exerciseGetter: "exerciseDetail",
      words: "wordLinkList",
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    exerciseGetter: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.exercise = newVal;
      },
    },
    words(newVal){
      if (!this.$route.query.group){
        let key = Object.keys(newVal)
        let maxKey = Math.max(...key)
        this.group = Math.floor(maxKey + 1)
      }
      else {
        this.group = parseInt(this.$route.query.group)
      }

    }
  },
  methods: {
    checkDuplicateWord(searchWord){
      let is_duplicate = false;
      this.groupWords.forEach(function (word){
        if(word.content === searchWord){
          is_duplicate =  true;
        }
      })
      return is_duplicate;

    },
    addWord() {
      if (this.checkDuplicateWord(this.newWord)) {
        this.dialog2 = true;
      } else {
        this.groupWords.push({
          id: this.newIndex--,
          content: this.newWord,
          exercise_id: this.exercise.id,
          group_type: false,
          group: this.group,
        });
      }
      if(this.groupWords.length >= 1){
        this.disable_save = false
      }
      else {
        this.disable_save= true
      }
      this.newWord = "";
    },
    editItem(item) {
      this.editedIndex = this.groupWords.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.groupWords[this.editedIndex].content = this.editedItem.content;
      this.dialog = false;
      this.disable_save = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    submitWord() {
      let validate = this.groupWords.every(item=> item.group_type == false)
      let payload = {word_groups: this.groupWords,exercise: this.exercise,}
      if (this.isUpdate){
        if(!validate){
          this.$store.dispatch(UPDATE_EXERCISE_CONTENT, payload)
              .then((data) => {
                if (data.data.status === 1) {
                  this.$toasted.show("Chỉnh sửa thành công!", {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration : 3000
                  })
                  this.message.success = "Chỉnh sửa thành công!";
                  this.$router.go(-1)
                }
                if (data.data.status === 0) {
                  this.message.error = data.data.message;
                }
              });
        }
        else {
          this.$toasted.show("Bạn chưa tick chọn đấp án", {
            theme: "bubble",
            position: "top-right",
            duration : 3000
          })
        }
      }
      else {
        if(validate){
          this.$toasted.show("Bạn chưa tick chọn đấp án", {
            theme: "bubble",
            position: "top-right",
            duration : 3000
          })
        }
        else {
          this.$store.dispatch(SAVE_EXERCISE_CONTENT, payload)
              .then((data) => {
                if (data.data.status === 1) {
                  this.$toasted.show("Tạo mới thành công !", {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration : 3000
                  })
                  this.message.success = "Tạo mới thành công !";
                  // toasted-primary
                  this.$router.go(-1)

                }
                if (data.data.status === 0) {
                  this.message.error = data.data.message;
                }
              });
        }
      }

    },
    deleteItem(item) {
      this.editedIndex = this.groupWords.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.groupWords.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
<style>
.question-content img {
  width: 150px !important;
}
</style>
